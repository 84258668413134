import { yupResolver } from '@hookform/resolvers/yup';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { Key, useCallback, useState } from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AppTextField from '@/common/components/form-inputs/AppTextField';
import Close from '@mui/icons-material/Close';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import {
	PracticeMarkActionDto,
	practiceRecordDetailDto,
} from '@/api/DTO/practiceRecord/practiceRecord.interface';
import AppFieldView from '@/common/components/form-view/AppFieldView';
import { useParams } from 'react-router-dom';
import {
	useGetPracticeMarkDetailQuery,
	useHandlePracticeMarkActionMutation,
} from '@/api/practiceRecord/practiceRecordApiSlice';
import dayjs from 'dayjs';
import {
	API_DATE_TIME_FORMAT,
	DISPLAY_CHINESE_DATE_TIME_FORMAT,
} from '@/utils/dateHelper';
import AppRadioGroup from '@/common/components/form-inputs/AppRadioGroup';
import AppDatePicker from '@/common/components/form-inputs/AppDatePicker';
import { optionType } from '@/api/enum/exercise.enum';
import AppElementView from '@/common/components/form-view/AppElementView';
import LocalDataGrid from '@/common/components/dataGrid/LocalDataGrid';
import { GridColDef } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { StyledImg } from './Styled';
import face1 from '@/assets/images/face1.png';
import face2 from '@/assets/images/face2.png';
import face3 from '@/assets/images/face3.png';
import face4 from '@/assets/images/face4.png';
import face5 from '@/assets/images/face5.png';

interface Props {
	practiceDetailData?: practiceRecordDetailDto;
}

const ContentDialog = (props: Props) => {
	const { practiceDetailData } = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const showModal = useModal();
	const { exerciseId } = useParams();
	const [handlePracticeMarkAction] = useHandlePracticeMarkActionMutation();

	const { data: practiceMarkDetailData } = useGetPracticeMarkDetailQuery(
		exerciseId || '',
		{
			refetchOnMountOrArgChange: true,
			skip: !exerciseId,
		}
	);

	const defaultValues: DefaultValues<PracticeMarkActionDto> = {
		marking: '',
		isPass: 'true',
		userExerciseId: exerciseId || '',
	};

	const formSchema = Yup.object().shape({
		isPass: Yup.string().required(t('errorMessage.pleaseSelect')),
		deadline: Yup.string()
			.nullable()
			.when('isPass', {
				is: 'false',
				then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
			}),
	});

	const methods = useForm<PracticeMarkActionDto>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const watchIsPass = methods.watch('isPass');

	const handleClose = () => {
		methods.reset();
		setOpen(false);
	};

	const onSubmit: SubmitHandler<PracticeMarkActionDto> = async (data) => {
		const response = await handlePracticeMarkAction(data);
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.updateExerciseStatusSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});

			handleClose();
		}
	};

	const findAnswer = useCallback((data: any) => {
		if (!data.answer) return [];
		console.log(data);
		const answerList = [
			data.answer.option1,
			data.answer.option2,
			data.answer.option3,
			data.answer.option4,
			data.answer.option5,
			data.answer.option6,
			data.answer.option7,
			data.answer.option8,
			data.answer.option9,
			data.answer.option10,
			data.answer.option11,
		];
		const optionsList = [
			data.practice.option1,
			data.practice.option2,
			data.practice.option3,
			data.practice.option4,
			data.practice.option5,
			data.practice.option6,
			data.practice.option7,
			data.practice.option8,
			data.practice.option9,
			data.practice.option10,
			data.practice.option11,
		];
		const answerString = answerList.map((item, index) => {
			if (item == true) {
				return optionsList[index];
			}
			return '';
		});

		return answerString;
	}, []);

	const transformResponse = useCallback((response?: any[]) => {
		if (response == null) return;
		const newResponse: any[] = [];
		response.forEach((item, index) => {
			newResponse.push({
				...item,
				index,
			});
		});
		return newResponse;
	}, []);

	const isShowSubmit = useCallback((data: any) => {
		if (
			(data?.practiceDetailDTO && data?.practiceDetailDTO?.status == 3) ||
			(data?.questionnaireDTO && data?.questionnaireDTO?.status == 3)
		) {
			return true;
		}
		return false;
	}, []);

	const showAnswerTitle = useCallback((data: any) => {
		const titleList = [
			data.practice.answerTitle1,
			data.practice.answerTitle2,
			data.practice.answerTitle3,
			data.practice.answerTitle4,
			data.practice.answerTitle5,
		];
		const answerList = [
			data.answer.shortAnswer1,
			data.answer.shortAnswer2,
			data.answer.shortAnswer3,
			data.answer.shortAnswer4,
			data.answer.shortAnswer5,
		];

		const answerString = answerList.map((item, index) => {
			if (item) {
				return {
					answerTitle: titleList[index],
					shortAnswer: item,
				};
			}
			return {};
		});
		console.log(answerString);
		return answerString;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'questionTitle',
			headerName: t('practiceRecord.subjects'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'userAnswerList',
			headerName: t('practiceRecord.userAnswer'),
			flex: 1,
			minWidth: 200,
			renderCell: (params) =>
				params.row.userAnswerList ? params.row.userAnswerList.join('、') : '-',
		},
		{
			field: 'answerList',
			headerName: t('practiceRecord.trueAnswer'),
			flex: 1,
			minWidth: 200,
			renderCell: (params) =>
				params.row.answerList ? params.row.answerList.join(',') : '-',
		},
		{
			field: 'status',
			headerName: t('practiceRecord.result'),
			flex: 1,
			minWidth: 200,
			renderCell: (params) =>
				params.row.isCorrect ? (
					<CheckIcon sx={{ color: '#2E7D32' }} />
				) : (
					<CloseIcon sx={{ color: '#BA1A1A' }} />
				),
		},
	];

	const formatEmo = useCallback((eNum: number) => {
		let src;
		if (eNum == 1) src = face1;
		if (eNum == 2) src = face2;
		if (eNum == 3) src = face3;
		if (eNum == 4) src = face4;
		if (eNum == 5) src = face5;
		return <img src={src} style={{ width: '60px', height: '60px' }} />;
	}, []);

	return (
		<>
			{practiceDetailData?.practiceDetailDTO &&
				practiceDetailData?.practiceDetailDTO?.status >= 3 && (
					<Button variant='contained' onClick={() => setOpen(true)}>
						{t('button.seeNewContent')}
					</Button>
				)}
			{practiceDetailData?.questionnaireDTO &&
				practiceDetailData?.questionnaireDTO?.status >= 3 && (
					<Button variant='contained' onClick={() => setOpen(true)}>
						{t('button.seeNewContent')}
					</Button>
				)}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='lg'
				fullWidth
			>
				<DialogTitle variant='h5'>
					{t('practiceRecord.pushedPractice')}
				</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<Grid container spacing={3}>
						{practiceMarkDetailData?.data.exerciseCategory == 1 && (
							<>
								<Grid item xs={6}>
									<AppFieldView
										label={t('practiceRecord.pushedTime')}
										value={dayjs(practiceMarkDetailData?.data.submitAt)
											.locale('zh-hk-my-setting')
											.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)}
									/>
								</Grid>
								<Grid item xs={6}>
									<AppFieldView
										label={t('practiceRecord.pushedTotal')}
										value={practiceMarkDetailData?.data.submitTotal}
									/>
								</Grid>
							</>
						)}
						{practiceMarkDetailData?.data.exerciseCategory == 2 && (
							<>
								<Grid item xs={3}>
									<AppFieldView
										label={t('practiceRecord.pushedTime')}
										value={dayjs(practiceMarkDetailData?.data.submitAt)
											.locale('zh-hk-my-setting')
											.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)}
									/>
								</Grid>
								<Grid item xs={3}>
									<AppFieldView
										label={t('practiceRecord.pushedTotal')}
										value={practiceMarkDetailData?.data.submitTotal}
									/>
								</Grid>
								<Grid item xs={3}>
									<AppFieldView
										label={t('practiceRecord.chapterType')}
										value={
											practiceMarkDetailData?.data.questionnaireAnswerDTO
												.chapterTypeShow
										}
									/>
								</Grid>
								<Grid item xs={3}>
									<AppFieldView
										label={t('practiceRecord.exerciseMethod')}
										value={
											practiceMarkDetailData?.data.questionnaireAnswerDTO
												.markMethodShow
										}
									/>
								</Grid>
							</>
						)}
					</Grid>

					{practiceMarkDetailData?.data.exerciseCategory == 1 && (
						<>
							<Divider sx={{ margin: '16px 0' }} />
							<Grid container>
								<Grid item xs={12}>
									<Typography variant='body2'>
										{t('practiceRecord.exerciseContent')}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<AppElementView
										label={t('practiceRecord.exerciseTitle')}
										value={
											<Typography variant='body2'>
												{
													practiceMarkDetailData?.data.practiceAnswerDTO
														.practice.title
												}
											</Typography>
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<AppElementView
										label={t('practiceRecord.questionTitle')}
										value={
											<Typography variant='body2'>
												{
													practiceMarkDetailData?.data.practiceAnswerDTO
														.practice.subTitle
												}
											</Typography>
										}
									/>
								</Grid>
							</Grid>
						</>
					)}

					<Divider sx={{ margin: '16px 0' }} />

					<Grid container>
						<Grid item xs={12}>
							<Typography variant='body2'>
								{practiceMarkDetailData?.data.exerciseCategory == 1
									? t('practiceRecord.userAnswerContent')
									: t('practiceRecord.answerContent')}

								{practiceMarkDetailData?.data.exerciseCategory == 2 &&
									`(${t(
										'practiceRecord.trueAnswer'
									)}:${practiceMarkDetailData?.data.questionnaireAnswerDTO.questionOptionAnswerDTO
										.filter((obj: any) => obj.isCorrect === true)
										.reduce((count: any, _: any) => count + 1, 0)}/${
										practiceMarkDetailData?.data.questionnaireAnswerDTO
											.questionOptionAnswerDTO.length
									})`}
							</Typography>
						</Grid>
						{practiceMarkDetailData?.data.exerciseCategory == 1 && (
							<>
								{practiceMarkDetailData?.data.practiceAnswerDTO.practice.type ==
									optionType.AnswerImage && (
									<>
										<Grid item xs={12}>
											{showAnswerTitle(
												practiceMarkDetailData?.data.practiceAnswerDTO
											).map((item, index) => (
												<AppElementView
													label={item.answerTitle}
													value={
														<Typography variant='body2'>
															{item.shortAnswer}
														</Typography>
													}
													key={index}
												/>
											))}
										</Grid>

										<Grid item xs={12}>
											{practiceMarkDetailData?.data.practiceAnswerDTO.answer
												.picList && (
												<AppElementView
													label={t('practiceRecord.uploadImage')}
													value={(
														practiceMarkDetailData?.data.practiceAnswerDTO
															.answer.picList || []
													).map((img: any, index: number) => (
														<StyledImg
															src={img.url}
															key={index}
															sx={{
																width: '130px',
																height: '130px',
															}}
														></StyledImg>
													))}
												/>
											)}
										</Grid>
									</>
								)}
								{practiceMarkDetailData?.data.practiceAnswerDTO.practice.type ==
									optionType.Radio && (
									<Grid item xs={12}>
										{findAnswer(
											practiceMarkDetailData?.data.practiceAnswerDTO
										).map((item, index) => (
											<AppElementView
												value={<Typography variant='body2'>{item}</Typography>}
												key={index}
											/>
										))}
									</Grid>
								)}
								{practiceMarkDetailData?.data.practiceAnswerDTO.practice.type ==
									optionType.Multiple && (
									<Grid item xs={12}>
										{findAnswer(
											practiceMarkDetailData?.data.practiceAnswerDTO
										).map((item, index) => (
											<AppElementView
												value={<Typography variant='body2'>{item}</Typography>}
												key={index}
											/>
										))}
									</Grid>
								)}
								{practiceMarkDetailData?.data.practiceAnswerDTO.practice.type ==
									optionType.Linear && (
									<Grid item xs={12}>
										{findAnswer(
											practiceMarkDetailData?.data.practiceAnswerDTO
										).map((item, index) => (
											<AppElementView
												value={<Typography variant='body2'>{item}</Typography>}
												key={index}
											/>
										))}
									</Grid>
								)}
								{practiceMarkDetailData?.data.practiceAnswerDTO.practice.type ==
									optionType.MoodThermometer && practiceMarkDetailData?.data.practiceAnswerDTO.answer && (
									<Grid item xs={12}>
										<AppElementView
											value={formatEmo(
												practiceMarkDetailData?.data.practiceAnswerDTO.answer?.emotionAnswer
											)}
										/>
									</Grid>
								)}
							</>
						)}
						{practiceMarkDetailData?.data.exerciseCategory == 2 && (
							<Grid item xs={12}>
								<LocalDataGrid
									rows={
										transformResponse(
											practiceMarkDetailData?.data.questionnaireAnswerDTO
												.questionOptionAnswerDTO
										) ?? []
									}
									columns={columns}
									rowCount={0}
									getRowId={(row) => row.index}
									pagination={false}
								/>
							</Grid>
						)}
					</Grid>

					<Divider sx={{ margin: '16px 0' }} />

					<Grid container spacing={3}>
						{isShowSubmit(practiceDetailData) && (
							<>
								<Grid item xs={6}>
									<AppRadioGroup
										control={methods.control}
										name='isPass'
										row
										options={[
											{ id: 'true', name: t('practiceRecord.pass') },
											{ id: 'false', name: t('practiceRecord.reject') },
										]}
									/>
								</Grid>
								{JSON.parse(watchIsPass) == false && (
									<Grid item xs={6}>
										<AppDatePicker
											minimumDate={dayjs()
												.add(0, 'day')
												.format(API_DATE_TIME_FORMAT)}
											control={methods.control}
											name='deadline'
											label={t('practiceRecord.expirationDate')}
											formatDate={API_DATE_TIME_FORMAT}
										/>
									</Grid>
								)}
								<Grid item xs={12}>
									<AppTextField
										control={methods.control}
										name='marking'
										label={t('common.remark')}
										maxLength={200}
										showMaxLength
									/>
								</Grid>
							</>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					{isShowSubmit(practiceDetailData) && (
						<Button
							type='submit'
							variant='contained'
							onClick={methods.handleSubmit(onSubmit)}
						>
							{t('button.confirm')}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ContentDialog;
