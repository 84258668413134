import { useTranslation } from 'react-i18next';
import SortIcon from '@mui/icons-material/Sort';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import LinearScaleOutlinedIcon from '@mui/icons-material/LinearScaleOutlined';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import { AnswerImageType } from '@/api/enum/exercise.enum';

export function usePracticeRecordOptions(options: any[]) {
	const { t } = useTranslation();
	const listMap: Record<string, any[]> = {
		statusList: [
			{ id: 0, name: t('practiceRecord.noDistribution') },
			{ id: 1, name: t('practiceRecord.distributioned') },
			{ id: 2, name: t('practiceRecord.testing') },
			{ id: 3, name: t('practiceRecord.toBeFix') },
			{ id: 4, name: t('practiceRecord.doAgain') },
			{ id: 5, name: t('practiceRecord.done') },
			{ id: 6, name: t('practiceRecord.overdue') },
		],
		exerciseCategoryList: [
			{ id: 1, name: t('practiceRecord.myExercise') },
			{ id: 2, name: t('practiceRecord.learningAdvice') },
		],
		correctionMethodList: [
			{ id: 1, name: t('practiceRecord.auto') },
			{ id: 2, name: t('practiceRecord.manual') },
		],
		questionTypeList: [
			{ id: 5, name: t('practiceRecord.answer&image'), icon: <SortIcon /> },
			{
				id: 1,
				name: t('practiceRecord.radio'),
				icon: <RadioButtonCheckedIcon />,
			},
			{
				id: 2,
				name: t('practiceRecord.multiple'),
				icon: <CheckBoxOutlinedIcon />,
			},
			{
				id: 3,
				name: t('practiceRecord.linearScale'),
				icon: <LinearScaleOutlinedIcon />,
			},
			{
				id: 6,
				name: t('practiceRecord.moodThermometer'),
				icon: <EmojiEmotionsOutlinedIcon />,
			},
		],
		answerImageOptionList: [
			{ id: AnswerImageType.Image, name: t('practiceRecord.image') },
			{ id: AnswerImageType.Answer, name: t('practiceRecord.answer') },
			{
				id: AnswerImageType.ImageAndAnswer,
				name: t('practiceRecord.imageAndAnswer'),
			},
			{
				id: AnswerImageType.ImageOrAnswer,
				name: t('practiceRecord.answerOrAnswer'),
			},
		],
		practiceCategoryList: [
			{ id: 1, name: t('practiceRecord.once') },
			{ id: 2, name: t('practiceRecord.loop') },
		],
		loopTypeList: [
			{ id: 1, name: t('practiceRecord.everyday') },
			{ id: 2, name: t('practiceRecord.weekly') },
			{ id: 3, name: t('practiceRecord.monthly') },
		],
		weekList: [
			{ id: 1, name: t('practiceRecord.monday') },
			{ id: 2, name: t('practiceRecord.tuesday') },
			{ id: 3, name: t('practiceRecord.wednesday') },
			{ id: 4, name: t('practiceRecord.thursday') },
			{ id: 5, name: t('practiceRecord.friday') },
			{ id: 6, name: t('practiceRecord.saturday') },
			{ id: 7, name: t('practiceRecord.sunday') },
		],
	};
	return options.reduce((result: Record<string, any[]>, type) => {
		if (listMap[type]) {
			result[type] = listMap[type];
		}
		return result;
	}, {});
}
