import dayjs from 'dayjs';
import { API_DATE_TIME_FORMAT } from './dateHelper';

type Valuable<T> = {
	[K in keyof T as T[K] extends null | undefined ? never : K]: T[K];
};

export function removeObjKeyWithEmptyValue<T extends {}, V = Valuable<T>>(
	obj: T
): V {
	return Object.fromEntries(
		Object.entries(obj).filter(([, v]) => {
			if (Array.isArray(v)) {
				return v.length > 0;
			}
			return v != null;
		})
	) as V;
}

export function toNullIfEmptyArray<T extends {}, V = Valuable<T>>(
	array: T
): V | undefined {
	if (!Array.isArray(array) || !array.length) {
		return undefined;
	}
	return array as V;
}

export function pluralize(
	count: number,
	noun: string,
	suffix = 's',
	specialNoun?: string
) {
	if (specialNoun) {
		return `${count} ${count > 1 ? specialNoun : noun}`;
	} else {
		return `${count} ${noun}${count > 1 ? suffix : ''}`;
	}
}

export function isJson(str: string) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export function getDatesInRange(
	startDateString?: string,
	endDateString?: string
) {
	if (
		startDateString &&
		dayjs(startDateString).isValid() &&
		endDateString &&
		dayjs(endDateString).isValid()
	) {
		const startDate = dayjs(startDateString);
		const endDate = dayjs(endDateString);

		const dates = [];

		let currentDate = startDate;
		while (
			currentDate.isBefore(endDate, 'day') ||
			currentDate.isSame(endDate, 'day')
		) {
			dates.push(currentDate);
			currentDate = currentDate.add(1, 'day');
		}
		return dates;
	} else return [];
}

export function getMonthsInRange(
	startDateString?: string,
	endDateString?: string
) {
	if (
		startDateString &&
		dayjs(startDateString).isValid() &&
		endDateString &&
		dayjs(endDateString).isValid()
	) {
		const startDate = dayjs(startDateString);
		const endDate = dayjs(endDateString);

		const monthes = [];

		let currentMonth = startDate;
		while (
			currentMonth.isBefore(endDate, 'month') ||
			currentMonth.isSame(endDate, 'month')
		) {
			if (
				startDateString &&
				dayjs(startDateString).isValid() &&
				endDateString &&
				dayjs(endDateString).isValid()
			) {
				const startDate = dayjs(startDateString);
				const endDate = dayjs(endDateString);

				const dates = [];

				let currentDate = startDate;
				while (
					currentDate.isBefore(endDate, 'day') ||
					currentDate.isSame(endDate, 'day')
				) {
					if (currentMonth.month() === currentDate.month())
						dates.push(currentDate);

					currentDate = currentDate.add(1, 'day');
				}

				monthes.push(dates);
			}
			currentMonth = currentMonth.add(1, 'month');
		}
		return monthes;
	} else return [];
}

export function showLabel(
	value: any,
	list: any = [],
	attrValue = 'id',
	attrLabel = 'name'
) {
	const obj = list.find(
		(item: { [x: string]: any }) => item[attrValue] == value
	);
	return obj ? obj[attrLabel] : value;
}

export function createDailyTasks(endDate: Date) {
	const endDateTime = new Date(endDate);
	const now = new Date();
	const tasks = [];
	let currentStartDate;

	// 如果当前时间晚于今天的12:30 PM，则使用当前时间作为第一个任务的开始时间
	// 否则，使用今天的12:30 PM作为第一个任务的开始时间
	if (
		now.getHours() > 12 ||
		(now.getHours() === 12 && now.getMinutes() >= 30)
	) {
		currentStartDate = new Date(now);
		// 如果当前时间已经是某一天的晚上11:59:59之后，需要设置为次日的12:30 PM
		if (
			currentStartDate.getHours() >= 23 ||
			(currentStartDate.getHours() === 23 &&
				currentStartDate.getMinutes() >= 59)
		) {
			currentStartDate.setDate(currentStartDate.getDate() + 1);
			currentStartDate.setHours(12, 30, 0, 0);
		}
	} else {
		currentStartDate = new Date(now);
		currentStartDate.setHours(12, 30, 0, 0);
		// 如果当前日期设置的12:30 PM小于当前时间（理论上不应该发生，除非有时间调整或时区问题），则使用明天的12:30 PM
		if (currentStartDate < now) {
			currentStartDate.setDate(currentStartDate.getDate() + 1);
		}
	}

	// 创建一个循环，直到当前开始日期超过结束日期
	while (currentStartDate <= endDateTime) {
		const startDate = new Date(currentStartDate);
		const endDate = new Date(startDate);
		endDate.setDate(endDate.getDate() + 1); // 设置为次日
		endDate.setHours(23, 59, 59, 999); // 设置为次日的23:59:59.999

		// 考虑到跨年情况，如果结束日期在当前年的最后一天之后，但是开始日期还在当前年，
		// 那么结束日期需要被设置为下一年同一日期的23:59:59
		if (
			endDate.getMonth() < endDateTime.getMonth() ||
			(endDate.getMonth() === endDateTime.getMonth() &&
				endDate.getDate() < endDateTime.getDate())
		) {
			// 这里其实不需要额外处理，因为上面的endDate.setDate(endDate.getDate() + 1)已经处理了跨年情况
			// 只需要确保endDate没有超过endDateTime即可
		}

		// 将任务添加到数组中
		tasks.push({
			start: dayjs(startDate).format(API_DATE_TIME_FORMAT),
			end: dayjs(endDate).format(API_DATE_TIME_FORMAT),
		});

		// 移动到下一天的12:30 PM
		currentStartDate.setDate(currentStartDate.getDate() + 1);
		currentStartDate.setHours(12, 30, 0, 0);
	}

	return tasks;
}

export function createWeekTasks(endTime: Date, week: number) {
	const endDate = new Date(endTime);

	const today = new Date();
	const todayHour = today.getHours();
	const todayMinute = today.getMinutes();

	const cycles = [];
	const todayDayOfWeek = today.getDay();

	const startDate = new Date(today);
	if (week == todayDayOfWeek) {
		startDate.setDate(today.getDate() + 0);
	} else if (todayDayOfWeek > week) {
		startDate.setDate(today.getDate() + 7 - todayDayOfWeek + week);
	} else if (todayDayOfWeek < week) {
		startDate.setDate(today.getDate() + week - todayDayOfWeek);
	}

	while (startDate <= endDate) {
		const start = new Date(startDate);
		if (start.toDateString() === today.toDateString()) {
			start.setHours(todayHour, todayMinute, 0, 0);
		} else {
			start.setHours(12, 30, 0, 0);
		}

		const end = new Date(start);
		end.setDate(start.getDate() + 7);
		end.setHours(23, 59, 59, 999);

		cycles.push({
			start: dayjs(start).format(API_DATE_TIME_FORMAT),
			end: dayjs(end).format(API_DATE_TIME_FORMAT),
		});
		// 将startDate设置为下一个周三
		startDate.setDate(startDate.getDate() + 7);
	}

	return cycles;
}

export function createMonthTasks(endDate: Date, day: number) {
	const result: { start: string; end: string }[] = [];
	const currentDate = new Date();
	const endDateTime = new Date(endDate);
	endDateTime.setHours(23, 59, 59, 99);
	let firstStart = true;
	while (currentDate <= endDateTime) {
		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		).getDate();
		const startDay = Math.max(day, currentDate.getDate());
		if (startDay > lastDayOfMonth || startDay > day) {
			currentDate.setMonth(currentDate.getMonth() + 1, day);
			continue;
		}

		let startTime = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			startDay
		);

		const endTime = getNextTargetDayDate(startTime, day);
		if (firstStart) {
			const today = new Date();
			today.setHours(0, 0, 0, 0);

			if (startTime.toDateString() === today.toDateString()) {
				const now = new Date();
				const twelveThirtyPM = new Date(today);
				twelveThirtyPM.setHours(12, 30, 0, 0);
				if (now >= twelveThirtyPM) {
					startTime = now;
				} else {
					startTime.setHours(12, 30, 0, 0);
				}
			} else {
				startTime.setHours(12, 30, 0, 0);
			}
			firstStart = false;
		} else {
			startTime.setHours(12, 30, 0, 0);
		}

		result.push({
			start: dayjs(startTime).format(API_DATE_TIME_FORMAT),
			end: dayjs(endTime).format(API_DATE_TIME_FORMAT),
		});

		currentDate.setMonth(currentDate.getMonth() + 1, day);
	}

	return result;
}

function getNextTargetDayDate(startTime: string | number | Date, day: number) {
	const currentDate = new Date(startTime);
	const targetDay = day;
	let year = currentDate.getFullYear();
	let month = currentDate.getMonth() + 1;

	// 循环直到找到一个有目标天数的日期
	// eslint-disable-next-line no-constant-condition
	while (true) {
		const newDate = new Date(year, month, targetDay);
		if (newDate.getMonth() === month) {
			newDate.setHours(23, 59, 59, 99);
			return newDate;
		} else {
			month++;

			if (month > 11) {
				year++;
				month = 0;
			}
		}
	}
}
