import { apiSlice } from '../apiSlice';
import { Paginated, SuccessResponse } from '../DTO/common.interface';
import {
	UserListOfCashCouponFilter,
	UserListOfCashCouponSummaryItem,
} from '../DTO/item.interface';
import {
	VoucherResDTO,
	VoucherItemResDTO,
	FilterShopItemReqDTO,
	FilterShopItemResDTO,
} from '../DTO/shop/shop.interface';

export const shopApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVoucher: builder.query<SuccessResponse<VoucherResDTO>, string>({
			query: (redeemCode) => ({
				url: `/web/shop/vouchers/codes/${redeemCode}`,
			}),
			providesTags: ['Voucher'],
		}),

		redeemVoucher: builder.mutation<SuccessResponse<null>, string>({
			query: (redeemCode) => ({
				url: `/web/shop/vouchers/codes/${redeemCode}/redeem`,
				method: 'POST',
			}),
			invalidatesTags: ['Voucher', 'VoucherList'],
		}),

		getVouchers: builder.query<SuccessResponse<VoucherItemResDTO[]>, void>({
			query: () => ({
				url: '/web/shop/vouchers',
			}),
		}),

		filterShopItems: builder.query<
			SuccessResponse<Paginated<FilterShopItemResDTO>>,
			FilterShopItemReqDTO
		>({
			query: (body) => ({
				url: '/web/shop/page',
				method: 'POST',
				body,
			}),
		}),

		filterVouchersUsersItems: builder.query<
			SuccessResponse<Paginated<UserListOfCashCouponSummaryItem>>,
			UserListOfCashCouponFilter
		>({
			query: (body) => ({
				url: '/web/shop/vouchers/users/page',
				method: 'POST',
				body,
			}),
			providesTags: ['VoucherList'],
		}),

		getVouchersItemTotal: builder.query<
			SuccessResponse<{ purchased: number }>,
			null
		>({
			query: () => ({
				url: '/web/shop/vouchers/item-total',
			}),
		}),
	}),
});

export const {
	useGetVoucherQuery,
	useRedeemVoucherMutation,
	useGetVouchersQuery,
	useFilterShopItemsQuery,
	useFilterVouchersUsersItemsQuery,
	useGetVouchersItemTotalQuery,
} = shopApiSlice;
