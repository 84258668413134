import { Option } from '@/api/DTO/common.interface';
import dayjs from 'dayjs';

export const INVALID_DATE = 'Invalid Date';
export const API_TIME_FORMAT = 'HH:mm:ss';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const DISPLAY_TIME_FORMAT = 'HH:mm';
export const DISPLAY_DATE_YEAR_FORMAT = 'YYYY';
export const DISPLAY_DATE_MONTH_FORMAT = 'YYYY-MM';
export const DISPLAY_DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DISPLAY_CHINESE_DATE_FORMAT = 'YYYY年MM月DD日';
export const DISPLAY_CHINESE_DATE_TIME_FORMAT = 'YYYY-MM-DD A hh:mm';

export function getDateQuarter(
	quarter: string,
	year = new Date().getFullYear()
) {
	const startDate = new Date(year, 0, 1);
	let endDate;

	switch (quarter) {
		case '1':
			endDate = new Date(year, 2, 31);
			break;
		case '2':
			startDate.setMonth(3, 1);
			endDate = new Date(year, 5, 30);
			break;
		case '3':
			startDate.setMonth(6, 1);
			endDate = new Date(year, 8, 30);
			break;
		case '4':
			startDate.setMonth(9, 1);
			endDate = new Date(year, 11, 31);
			break;
		default:
			throw new Error('Invalid quarter');
	}

	return [
		dayjs(startDate).format(DISPLAY_DATE_FORMAT),
		dayjs(endDate).format(DISPLAY_DATE_FORMAT),
	];
}

export function getDateMonth(d: string) {
	const date = new Date(d);
	const year = date.getFullYear();
	const month = date.getMonth();
	const startDate = new Date(year, month, 1);
	const endDate = new Date(year, month + 1, 0);

	return [
		dayjs(startDate).format(DISPLAY_DATE_FORMAT),
		dayjs(endDate).format(DISPLAY_DATE_FORMAT),
	];
}

export function getCompletedQuartersForYear(
	targetYear: number
): { id: string; name: string }[] {
	if (!targetYear) return [];
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth() + 1;
	if (targetYear !== currentYear) {
		if (targetYear < currentYear) {
			// 对于过去年份，返回所有季度
			const arr = [
				{ id: '1', name: '第一季度' },
				{ id: '2', name: '第二季度' },
				{ id: '3', name: '第三季度' },
				{ id: '4', name: '第四季度' },
			];
			if (targetYear == 2024) return [arr[3]];
			return arr;
		} else {
			// 对于未来年份，返回一个空数组
			return [];
		}
	}
	const quarters = [
		{ id: '1', startMonth: 1, name: '第一季度' },
		{ id: '2', startMonth: 4, name: '第二季度' },
		{ id: '3', startMonth: 7, name: '第三季度' },
		{ id: '4', startMonth: 10, name: '第四季度' },
	];
	const completedQuarters = quarters
		.filter((quarter) => currentMonth >= quarter.startMonth)
		.map((quarter) => ({ id: quarter.id, name: quarter.name }));
	if (targetYear == 2024) return [completedQuarters[3]];
	return completedQuarters;
}

export function getFutureDate(date: Date, day: number) {
	const currentDate = date;
	const later = new Date(currentDate);
	later.setDate(currentDate.getDate() + day);
	return dayjs(later).format(API_DATE_TIME_FORMAT);
}

export function replaceTimeTo235959(dateTimeStr: string) {
	const dateTimeParts = dateTimeStr.split('T');
	if (dateTimeParts.length !== 2) {
		throw new Error('Invalid date time format');
	}

	const [datePart, timeZonePart] = dateTimeParts;
	const newTimePart = '23:59:59';
	const newDateTimeStr = `${datePart}T${newTimePart}${timeZonePart.slice(
		newTimePart.length
	)}`;

	return newDateTimeStr;
}

export function formatDate(dateString: string | number | Date): string {
	const weekMap = [
		'星期日',
		'星期一',
		'星期二',
		'星期三',
		'星期四',
		'星期五',
		'星期六',
	];

	const monthMap = [
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11',
		'12',
	];

	const date = new Date(dateString);

	// 提取日期的各个部分
	const year = date.getFullYear();
	const monthIndex = date.getMonth();
	const month = monthMap[monthIndex];
	const day = date.getDate();
	const weekIndex = date.getDay();
	const week = weekMap[weekIndex];
	const hour = date.getHours();
	const minute = date.getMinutes();
	const period = hour >= 12 ? 'PM' : 'AM';
	const formattedHour = hour % 12 || 12;

	// 格式化日期字符串
	const formattedDate = `${year}年${month}月${day}日${week} ${formattedHour}:${
		minute < 10 ? '0' + minute : minute
	}${period}`;
	return formattedDate;
}
