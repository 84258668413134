import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Close from '@mui/icons-material/Close';
import { showLabel } from '@/utils/helper';
import { usePracticeRecordOptions } from '@/hooks/usePracticeRecordOptions';
import { GridColDef } from '@mui/x-data-grid';
import { useCallback } from 'react';
import { formatDate, replaceTimeTo235959 } from '@/utils/dateHelper';
import { cloneDeep } from 'lodash';
import BasicTable from './BasicTabe';
import dayjs from 'dayjs';

interface Props {
	loopDialogIsOpen: boolean;
	setLoopDialogIsOpen: any;
	loopType: number;
	dailyList: { start: string; end: string }[];
	callApiFn: any;
	methods: any;
	watchLoopEndTime: string;
}

const SettingLoopPractice = (props: Props) => {
	const {
		loopDialogIsOpen,
		setLoopDialogIsOpen,
		loopType,
		dailyList,
		methods,
		callApiFn,
		watchLoopEndTime,
	} = props;
	const { t } = useTranslation();
	const handleClose = () => {
		setLoopDialogIsOpen(false);
	};

	const { loopTypeList } = usePracticeRecordOptions(['loopTypeList']);

	const columns: GridColDef[] = [
		{
			field: 'times',
			headerName: t('practiceRecord.times'),
			minWidth: 100,
		},
		{
			field: 'startTime',
			headerName: t('practiceRecord.sendDate'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'deadline',
			headerName: t('practiceRecord.deadline'),
			flex: 1,
			minWidth: 200,
		},
	];

	const transformResponse = useCallback((response?: any[]) => {
		if (response == null) return;
		return response.map((item, index) => ({
			index: index,
			times: index + 1,
			startTime: formatDate(item.start),
			deadline: formatDate(item.end),
		}));
	}, []);

	const handleSubmit = () => {
		const newData = cloneDeep(methods.getValues());
		if (newData.loopEndTime) {
			newData.loopEndTime = replaceTimeTo235959(newData.loopEndTime);
		}
		newData.loopPractices = dailyList.map((item, index) => ({
			times: index + 1,
			startTime: item.start,
			deadline: item.end,
		}));
		callApiFn(newData);
	};

	return (
		<>
			<Dialog
				open={loopDialogIsOpen}
				onClose={handleClose}
				maxWidth='md'
				fullWidth
			>
				<DialogTitle variant='h5'>
					{t('practiceRecord.assignedLoopExercise')}
				</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<Typography variant='body1'>
						{t('practiceRecord.loopExerciseStartText')}
						{showLabel(loopType, loopTypeList)}
						{t('practiceRecord.loopExerciseEndText')}
						{dayjs(watchLoopEndTime).format('YYYY年MM月DD日')}
						{t('practiceRecord.over')}。
					</Typography>
					<br />
					<Typography variant='body1'>
						{t('practiceRecord.confirmLoopExerciseDate')}:
					</Typography>
					<BasicTable
						rows={transformResponse(dailyList) || []}
						columns={columns}
					/>
				</DialogContent>
				<DialogActions sx={{ padding: '20px' }}>
					<Button type='submit' onClick={() => setLoopDialogIsOpen(false)}>
						{t('button.cancel')}
					</Button>
					<Button variant='contained' onClick={handleSubmit}>
						{t('button.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SettingLoopPractice;
