import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
	name: string,
	calories: number,
	fat: number,
	carbs: number,
	protein: number
) {
	return { name, calories, fat, carbs, protein };
}

interface Props {
	rows: any[];
	columns: any[];
}

export default function BasicTable(props: Props) {
	const { columns, rows } = props;
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label='simple table'>
				<TableHead sx={{ backgroundColor: '#F1F0F3' }}>
					<TableRow>
						{columns.map((item, index) => (
							<TableCell key={index} align='left'>
								{item.headerName}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow
							key={row.index}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<TableCell component='th' scope='row' align='left'>
								{row.times}
							</TableCell>
							<TableCell align='left'>{row.startTime}</TableCell>
							<TableCell align='left'>{row.deadline}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
